.header {
    padding: 3vh 3vh 1vh 3vh ;
}
.header__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header__top__date {
    font-size: 2.2vh;
}

.header__top__logo {
    height: 2.4vh;
}

.header__divider {
    height: 0.1vh;
    background: rgba(255, 255, 255, 0.30);
    margin: 1vh 0
}
.header__bottom {
    text-align: left;
    font-size: 1.6vh;
}
.header__caption {
    font-size: 1.2vh;
}