.service-container {
    justify-content: center;
    transition: opacity 500ms ease-in;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.service-container__circle {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: -1vh;
    max-width: 43vh;
}

.service-container__description {

    width: 90%;
    height: 100%;
    transition: opacity 500ms ease-in;
    font-size: 1.5vh;
    margin-right: auto;
    margin-left: auto;
    padding: 0px 5%;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.service-container__description::-webkit-scrollbar {
    display: none;
}


.status-icon {
    width: 2.4vh;
    margin-bottom: -2px;
    margin-left: 0.2vh;
}

.divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.30);
    margin: 1.2vh 0.8vh;
}

.label-divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.30);
    margin: 1vh 3vh 1.5vh;
}

.service-title {
    font-size: 1.8vh;
    text-align: center;
    z-index: 102;
}

.day-overview__title {
    font-size: 1.3vh;
    font-weight: bolder;
    text-align: left;
}

.day-overview__row {
    display: flex;
    flex-direction: row;
    color: var(--primaryColorLight);
    padding-bottom: 1.5vh;
}

.day-overview__row__element {
    display: flex;
    flex-direction: row;
    padding: 0px 0.8vh 0px 0px;
    align-items: center;
}

.day__container {
    display: flex;
    flex-direction: row;
}

.day-desc__container {
    width: 100%;
}

.expand-icon {
    width: 4.1vh;
    padding: 0.2vh 1.1vh;
}

.kpi-container {
    padding-bottom: 2vh;
}

.kpi-container__title {
    text-align: left;
}

.kpi-container__desc {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    color: var(--primaryColorLight);
    padding-top: 0.8vh;
}

.kpi-container__title__column-1 {
    width: 50%;
}
.kpi-container__title__column-2 {
    padding-right: 1vh;
    width: 50%;
}

.kpi-container__title__column-text {
    padding: 0;
}

.kpi-container__message {
    border-left: solid;
    border-color: var(--redColor);
    padding: 0px 1vh;
    margin-left: -1.3vh;
    color: var(--redColor);
}
.text--red {
    color: var(--redColor);
}

.small-month-container {
    width: 15%;
    padding: 16px;
}

.info-container {
    position: absolute;
    width: 100%;
    height: 100%;
}
.container {
    overflow: hidden;
    width: 100%;
    height: 46vh;
    position: relative;
}
.inactive-service {
    opacity: 0.8;
}
