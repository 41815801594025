@keyframes spin {
    from {
        transform:rotate(0deg)
    }
    to {
        transform:rotate(360deg)
    }
}

.spinning-part{
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spinning-part--large {
    animation-name: spin;
    animation-duration: 12000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.spinning-part--medium {
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spinning-part--small {
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spinning-part--1 {
    transform-origin: -3.1% 39.7%;
    transform: rotate(45deg);
    animation-duration: 6500ms;
}
.spinning-part--2 {
    transform-origin: 8.1% 24.5%;
    animation-duration: 10000ms;
}
.spinning-part--3 {
    transform-origin: 19.6% 41%;
    transform: rotate(50deg);
    animation-duration: 7000ms;
}
.spinning-part--4 {
    transform-origin: 38.6% 41%;
    animation-duration: 6900ms;
}
.spinning-part--5 {
    transform-origin: 43.2% 14%;
    animation-duration: 12000ms;
}
.spinning-part--6 {
    transform-origin: 66.45% 24.2%;
    animation-duration: 10500ms;
}
.spinning-part--7 {
    transform-origin: 84.5% 38%;
    animation-duration: 6600ms;
}


.st0{opacity:0.1125;}
.st1{fill:#FFFFFE;}
.st2{fill:#CAC9C4;}
.st3{fill:#EEEEEA;}
.st4{fill:#41746F;}
.st5{fill:#558E86;}