.intro {
    padding: 0 2vh;
    text-align: left;
    color: white;
    overflow: auto;
    height: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-size: 1.3vh;
}

.intro::-webkit-scrollbar {
    display: none;
}

.intro__desc {
    font-size: 1.5vh;
    padding: 0.8vh 0.8vh;
    text-align: justify;
}
.video-button {
    padding: 0vh 0.8vh 0.8vh 0.8vh;
    cursor: pointer;
    display: flex;
    align-content: center;
    font-weight: bolder;
    font-size: 1.5vh;
}

.video-icon {
    font-size: 2.7vh;
    padding-right: 0.1vh;
    margin-top: -0.1vh;
}