body {
  margin: 0;
  background: var(--primaryColorBackground);
  color: white;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  /*Palette*/
  --redColor: #D76C75;
  --purpleColor: #B254CD;
  --blueColor: #6AC0FF;
  --primaryColorBackground: #232F46;
  --primaryColorBackgroundTransparent:rgba(35, 47, 70, 0.9);
  --primaryColorDark: #2E4267;
  --primaryColorMedium: #415271;
  --primaryColorLight: #8191AF;

  /*Map Palette*/
  --mapRedLight: #FF7275;
  --mapRedDark: #A84446;
  --mapPurpleLight: #B254CD;
  --mapPurpleDark: #7F2E96;
  --mapBlueLight: #6AC0FF;
  --mapBlueDark: #224D6D;
  --mapGrayLight: #8191AF;
  --mapGrayDark: #415271;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
