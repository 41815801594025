.service-view {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 86%;
    overflow: hidden;
    padding-top: 12vh;
}

.service-view__up {
    align-items: flex-start;
    padding-top: 0;
}

.service-view__circle {
    margin-top: 4vh;
    width: 70%;
    /*width: 18vw;*/
    min-width: 130px;

    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}

.service-view__center-element {
    width: 100%;
    height: 100%;
    min-width: 230px;
    /*width: 28vw;*/

    overflow: hidden;
}
.faded-element {
    opacity: 0.4;
    max-width: 35vh;
}
.faded-inactive-element {
    opacity: 0.8;
    max-width: 35vh;
}

.fullscreen-view {
    width: 100%;
    overflow: hidden;
}

.center-element__container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 88vh;
    position: absolute;
    left: 36vw;
    width: 28vw;
    /*z-index: 100;*/
}

.center-element__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 40vh;
    height: 46vh;
    z-index: 100;
}

.vertical-divider {
    height: 95%;
    width: 1.5%;
    background: var(--primaryColorMedium);
    opacity: 0.4;
    border-radius: 20px;
    z-index: 100;
}

.background {
    position: absolute;
    bottom: -2vh;
    width: 50%;
    margin-left: 25%;
}
.navWheel {
    position: absolute;
    bottom: 0;
    left: 0px;
    margin-left: -13vh;
    margin-bottom: -13vh;
}
.navWheel__logo {
    position: absolute;
    bottom: 2.6vh;
    left: 1.7vh;
    width: 4vh;
    cursor: pointer;
}
.navWheel__home-label {
    position: absolute;
    bottom: 0.4vh;
    left: 1.3vh;
    width: 4.5vh;
    cursor: pointer;
    font-size: 1.5vh;
}

.test-transition {
    transition: opacity 1s;
}

.key-container{
    position: absolute;
    right: 2vh;
    bottom: 2vh;
    width: 28vh;
    overflow: hidden;
    border-radius: 8px;
}

.full-width {
    display: contents;
}

.card {
    position: absolute;
    will-change: transform, width;
    border-radius: 50%;
    background: rgb(35,47,70);
    background: radial-gradient(circle, rgba(35,47,70,1) 35%, rgba(35,47,70,0) 100%);
}

.blink-card {
    /*position: absolute;*/
    will-change: width;
}

.tapme {
    position: absolute;
    left: 20vh;
    bottom: 20vh;
    width: 10vh;
    height: 5vh;
}

.popup-text {
    position: absolute;
    left: 13vh;
    bottom: 13vh;
    width: 32vw;
}

.popup-card {
    position: absolute;
    bottom: 0vh;
    width: 30vw;
    margin-left: -15vw;
}

.guided-tour-hand {
    position: absolute;
    width: 4vw;
    z-index: 200;
}

.absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.countdown-popup {
    font-size: 2vh;
    position: absolute;
    bottom: 20vh;
    left: 50vw;
    width: 30vw;
    height: 8vh;
    margin-left: -15vw;
    border-radius: 0.5vh;
    background: var(--mapGrayDark);
    color: white;
    padding: 4vh 2vh 0vh 2vh;
    opacity: 0.5;
    z-index: 300;
    -webkit-box-shadow: 0px 0px 50px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 50px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 50px -10px rgba(0,0,0,0.75);
    text-transform: uppercase;
}