.popup {
    position: fixed;
    width: 100%;
    height: 92vh;
    top: 8vh;
    left: 0;
    background: var(--primaryColorBackground);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-content: center;
}
.video {
    width: 112vh   !important;
    height: 63vh !important;
    margin-top: 5vh;
}
.close-button {
    position: absolute;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    background: var(--primaryColorMedium);
    color: var(--primaryColorLight);
    bottom: 4vh;
    left: 4vh;
    cursor: pointer;
    font-size: 3vh;
}
.close-button__icon {
    margin-top: 1vh;
}