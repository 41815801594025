.defaultCircle {
    fill: var(--primaryColorBackground);
}

.circle {
    fill: var(--primaryColorDark);
}

.redCircle {
    fill: var(--redColor);
}

.purpleCircle {
    fill: var(--purpleColor);
}

.blueCircle {
    fill: var(--blueColor);
}

.small-calendar-container {
    width: 12.5%;
    padding: 1%;
}
