.history-container {
    overflow: hidden;
}
.calendar-container {
    color: var(--primaryColorLight);
    height: 20vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-top: 1vh;
    padding-bottom: 2vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.calendar-container::-webkit-scrollbar {
    display: none;
}


/*.calendar-button__container {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    padding: 0px 10px;*/
/*}*/

/*.calendar-button {*/
/*    background-color: transparent; !* Green *!*/
/*    border: none;*/
/*    color: white;*/
/*    padding: 10px 16px;*/
/*    display: inline-block;*/
/*    font-weight: 700;*/
/*}*/

:focus {
    outline: none !important;
}

.DayPicker {
    font-size: 1.1vh !important;
    font-weight: 400;
}

.DayPicker-Day {
    width: 1.6vh;
    height: 1.8vh;
    padding: 0.2vh !important;
    background-color: var(--primaryColorDark);
    color: var(--primaryColorLight);
    border: 2px solid transparent;
}

.DayPicker-Day--outside {
    opacity: 0.3;
    background-color: var(--primaryColorDark) !important ;
}

 .DayPicker-Month {
     border-spacing: 0.8vh !important;
     border-collapse: initial !important;
 }

.DayPicker-Day.DayPicker-Day--selected{
    border: 2px solid white;
    font-weight: 600;
}

.DayPicker-Weekday {
    color: var(--primaryColorLight);
}

.DayPicker-WeekdaysRow {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--primaryColorLight);
}

.DayPicker-Day.DayPicker-Day--selected {
    background-color: var(--blueColor);
    color: var(--primaryColorDark);
}

.DayPicker-Day.DayPicker-Day--disabled {
    color: var(--primaryColorLight);
    opacity: 0.6;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: var(--blueColor);
    /*color: var(--primaryColorDark);*/
}

.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: var(--primaryColorDark);
    color: var(--primaryColorDark);
}

.DayPicker-Caption {
    display: none !important;
}

.DayPicker-NavBar {
    display: none !important;
}

.DayPicker-Weekdays {
    display: none !important;
}

.colored-calendar-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.7vh;
    text-align: left;
    padding-top: 1vh;
    color: var(--primaryColorLight);
    cursor: pointer;
    max-width: 38vh;
    margin-right: auto;
    margin-left: auto;
}

.calendar-divider {
    height: 1px;
    background: var(--primaryColorMedium);
    width: 80%;
    margin-left: 10%;
    opacity: 0.5;
}

.switch-date__container {
    padding: 0.2vh;
    background: var(--primaryColorDark);
    width: 70%;
    max-width: 36vh;
    margin-right: auto;
    margin-left: auto;
    /*margin-left: 15%;*/
    margin-top: 1vh;
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.2);
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    font-size: 1.2vh;
    box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.3);
}

.switch-date__icon {
    color: var(--primaryColorLight);
    font-size: 1.6vh;
    margin-top: 2px;
}

.switch-date__icon__container {
    width: 100%;
    cursor: pointer;
}

.switch-date__label__container {
    width: 25vh
}